<template>
    <div class="nav">
        <div class="nav__container">
            <div class="nav__logo">
                <img src="../assets/images/tenant-logo.svg" alt="Tenant Evaluation" />
            </div>
            <ul :class="`nav__steps nav__steps--${routesClasses}`">
                <li class="nav__step nav__step--intro">
                    Create you TE login
                </li>
                <li class="nav__step nav__step--sub-list">
                    Select your plan
                    <ul>
                        <li>Package</li>
                        <li>Add Ons</li>
                        <li>Review</li>
                    </ul>
                </li>
                <li class="nav__step">
                    Tell us about your organization
                </li>
                <li class="nav__step">
                    Agree and get started with TE
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navigation',
    props: {
        routesClasses: String,
    },
};
</script>

<style lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.nav {
    &__container {
        padding: 90px 57px;
    }

    &__logo {
        width: 38px;
        height: auto;
        display: block;
        padding-left: 20px;
        margin-bottom: 234px;
    }

    &__step {
        font-size: 14px;
        line-height: 16px;
        position: relative;

        &:before {
            position: absolute;
            left: -32px;
            top: 0;
            background-image: icon('icon-check.svg');
            background-color: $primary-color;
            width: 18px;
            height: 18px;
        }

        &:not(:last-child) {
            margin-bottom: 57px;
        }

        &:first-child {
            color: $success-color;

            &:before {
                content: '';
            }
        }

        &:nth-child(2) {
            color: $white-color;
        }

        &--sub-list {
            > ul {
                list-style: none;
                padding-left: 0;

                > li {
                    font-size: 12px;
                    line-height: 14px;
                    margin-top: 13px;
                }
            }
        }
    }

    &__steps {
        list-style: none;
        padding-left: 0 !important;
        position: relative;

        &:before {
            content: '';
            position: absolute;
            width: 2px;
            height: 66px;
            left: -24px;
            top: 18px;
            background-image: linear-gradient($white-color 33%, rgba(255, 255, 255, 0) 0%);
            background-position: right;
            background-size: 2px 7px;
            background-repeat: repeat-y;
        }

        li {
            color: $navigation-unselected-step-color;
        }

        &--package {
            &:before {
                height: 94px;
            }

            .nav {
                &__step {
                    &--sub-list {
                        ul {
                            li {
                                &:first-child {
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--add-ons {
            &:before {
                height: 118px;
            }

            .nav {
                &__step {
                    &--sub-list {
                        ul {
                            li {
                                &:not(:last-child) {
                                    color: $white-color;
                                }
                            }
                        }
                    }
                }
            }
        }

        &--review {
            &:before {
                height: 146px;
            }
        }

        &--organization-info {
            &:before {
                height: 294px;
            }
        }

        &--get-start {
            &:before {
                height: 94px;
            }
        }
    }
}
</style>
