<template>
    <div :class="`dashboard dashboard--${routesClasses}`">
        <div class="dashboard__navigation">
            <Navigation :routes-classes="routesClasses" />
        </div>
        <div class="dashboard__content">
            <MemberStatus v-if="routesClasses !== 'welcome'" />
            <div class="dashboard__container">
                <router-view></router-view>
            </div>
        </div>
    </div>
</template>

<script>

import Navigation from '@/components/Navigation';

import MemberStatus from '@/components/MemberStatus';

export default {
    name: 'Dashboard',
    components: {
        Navigation,
        MemberStatus,
    },
    computed: {
        routesClasses() {
            return this.$route.name.toLowerCase().replace(/ /g, '-');
        },
    },
};
</script>

<style scoped lang="scss">
@import 'src/scss/abstracts/variables';

.dashboard {
    display: grid;
    grid-template-columns: $navigation-width auto;
    min-height: 100vh;

    &:not(&--welcome) {
        .dashboard {
            &__content {
                background: $gray-250;
            }
        }
    }

    &__navigation {
        background: $primary-color;
    }

    &__content {
        padding: 110px 60px 120px;
    }

    &__container {
        max-width: 780px;
        margin: 0 auto;
        height: 100%;
    }
}
</style>
