<template>
    <div class="member">
        <h3>Gold Member</h3>
        <span>First Service Residential</span>
    </div>
</template>

<script>
export default {
    name: 'MemberStatus',
};
</script>

<style scoped lang="scss">
@import './src/scss/abstracts/variables';
@import './src/scss/abstracts/functions';

.member {
    position: absolute;
    top: 0;
    right: 183px;
    padding: 8px 38px 15px;
    background: $primary-color;

    h3 {
        font-family: $alternate-font;
        color: $gold-color;
        font-size: 18px;
        font-style: italic;
        font-weight: 700;
        margin-bottom: 0;
    }

    span {
        line-height: 23px;
        font-size: 14px;
        color: $white-color;
    }
}
</style>